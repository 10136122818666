<template>
  <div class="service_type">
    <div class="content_header">
      <el-button type="primary" size="small" @click="addServiceType">新增服务类型</el-button>
    </div>
    <div class="content_main">
      <el-table
        v-loading="loading"
        :header-cell-style="headerCellStyle"
        :data="tableData"
        style="width: 100%"
        @row-click="onDetail">
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="grandFatherName"
          label="一级服务类型">
        </el-table-column>
        <el-table-column
          prop="parentName"
          label="二级服务类型">
        </el-table-column>
        <el-table-column
          prop="name"
          label="服务类型名称">
        </el-table-column>
        <el-table-column
          width="100px"
          label="操作">
          <template slot-scope='scoped'>
            <!-- <span
              v-if="scoped.row.enable === globalVar.SERVICE_TYPE_ENABLED_STATUS_1"
              class="text_color_danger tw-cursor-pointer tw-mr-10px"
              @click.stop="changeServiceTypeStatus(scoped.row, globalVar.SERVICE_TYPE_ENABLED_STATUS_0)">
              禁用
            </span>
            <span
              v-else
              class="text_color_active tw-cursor-pointer"
              @click.stop="changeServiceTypeStatus(scoped.row, globalVar.SERVICE_TYPE_ENABLED_STATUS_1)">
              启用
            </span> -->
            <span
              class="text_color_danger tw-cursor-pointer"
              @click.stop="onDelete(scoped.row)">
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content_footer">
      <el-pagination
        hide-on-single-page
        background
        @current-change="getServiceTypeList"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      loading: false,
      // 表格数据
      tableData: [],
      // 分页对象
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    // 表格头
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    },
    globalVar () {
      return globalVar
    }
  },
  created () {
    this.getServiceTypeList()
  },
  methods: {
    // 获取服务列表
    getServiceTypeList () {
      this.loading = true
      api.getServiceTypeList({
        ...this.pagination,
        // 查询级别, 1、2
        level: 3
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },

    // 修改服务类型状态
    changeServiceTypeStatus (item, status) {
      const msg = {
        title: '',
        message: ''
      }
      if (status) {
        msg.title = '启用服务类型'
        msg.message = '确定要启用此服务类型？'
      } else {
        msg.title = '禁用服务类型'
        msg.message = '确定要禁用此服务类型？'
      }
      this.$msgbox({
        title: msg.title,
        message: msg.message,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.toggleServiceTypeEnabled(item, instance, done, status)
          } else {
            done()
          }
        }
      })
    },

    /**
     * 启用禁用
     */
    toggleServiceTypeEnabled (item, instance, done, status) {
      instance.confirmButtonLoading = true
      api.toggleServiceTypeEnabled(item.id, {
        enable: status
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          // 重新获取列表
          this.getServiceTypeList()
        } else {
          this.$message.error(res.data.message)
        }
        done()
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        instance.confirmButtonLoading = false
      })
    },

    /**
     * 点击删除
     */
    onDelete (row) {
      this.$msgbox({
        title: '确认',
        message: '确定要删除此服务类型？',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.deleteItem(row, instance, done)
          } else {
            done()
          }
        }
      })
    },
    /**
     * 删除服务类型
     */
    deleteItem (row, instance, done) {
      instance.confirmButtonLoading = true
      api.deleteServiceType(row.id).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          // 重新获取列表
          this.getServiceTypeList()
        } else {
          this.$message.error(res.data.message)
        }
        done()
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        instance.confirmButtonLoading = false
      })
    },

    // 新增服务类型
    addServiceType (row) {
      const { href } = this.$router.resolve({
        path: '/resource-require/service-type-manage/add-service-type'
      })
      window.open(href, '_blank')
    },
    // 点击详情
    onDetail (row) {
      const { href } = this.$router.resolve({
        path: `/resource-require/service-type-manage/edit-service-type/${row.formId}`
      })
      window.open(href, '_blank')
    },

    // 页码变化
    pageNumChange (pageNum) {
      console.log('分页变化')
    },
    // 分页
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    }
  }
}
</script>

<style lang="scss" scoped>
  .service_type {
    width: 980px;
    background-color: #ffffff;
    border: 1px solid #DCDFE6;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 30px 40px;
    .content_header {
      margin-bottom: 20px;
    }
    .content_main {
      margin-bottom: 20px;
    }
    .content_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
  }
</style>
